var _a;
import _ from "lodash";
import Bot from "./components/Bot";
import EmotionDemo from "./components/EmotionDemo";
import Journal from "./components/Journal";
import React from 'react';
export var allModules = {
    'journal': {
        name: 'Journal',
        path: '/journal',
        component: React.createElement(Journal, null),
    },
    'phq9': {
        name: 'PHQ9',
        path: '/phq9',
        component: React.createElement(Bot, { mainDialog: "assessor_phq9" }),
    },
    'gad7': {
        name: 'GAD7',
        path: '/gad7',
        component: React.createElement(Bot, { mainDialog: "assessor_gad7" }),
    },
    'image': {
        name: 'Image',
        path: '/image',
        component: React.createElement(Bot, { mainDialog: "image_therapy" }),
    },
    'emotion-demo': {
        name: 'Emotion Inference',
        path: '/emotion-demo',
        component: React.createElement(EmotionDemo, null),
    },
    'clean-therapy': {
        name: 'Clean Therapy',
        path: '/clean-therapy',
        component: React.createElement(Bot, { mainDialog: "clean_language" }),
    },
    'cbt': {
        name: 'Conversationer',
        path: '/conversationer',
        component: React.createElement(Bot, { mainDialog: "cbt" }),
    },
};
var enabledModules = (_a = process.env.ENABLED_MODULES) !== null && _a !== void 0 ? _a : Object.keys(allModules).join(',');
var modules = _(allModules).transform(function (acc, value, key) {
    if (enabledModules.includes(key)) {
        acc[key] = value;
    }
}).values().value();
export default modules;
