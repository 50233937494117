import { ask, condition, run, say } from "../../bot/bot-schema";
import lastFeeling from '../../actions/last-feeling';
import toLowers from '../../actions/to-lowers';
import getRandom from '../../actions/get-random';
import randomOption from "../../actions/random-option";
import getContext from '../../actions/get-context';
import lastNoun from '../../actions/last-noun';
export default {
    clean_language: [
        getContext({ key: "feeling", from: "earliest", out: "first_feeling" }),
        getContext({ key: "feeling", from: "latest", out: "last_feeling" }),
        condition(function (_a) {
            var first_feeling = _a.first_feeling, last_feeling = _a.last_feeling;
            return first_feeling && first_feeling !== last_feeling;
        }, say('When we first spoke you were feeling {{ first_feeling }}')),
        condition(function (_a) {
            var last_feeling = _a.last_feeling;
            return last_feeling;
        }, say('Last time you were feeling {{ last_feeling }}')),
        randomOption({ out: "feeling_question", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var feeling_question = _a.feeling_question;
            return feeling_question === "option1";
        }, say("How are you feeling today?")),
        condition(function (_a) {
            var feeling_question = _a.feeling_question;
            return feeling_question === "option2";
        }, say("What's on your mind?")),
        condition(function (_a) {
            var feeling_question = _a.feeling_question;
            return feeling_question === "option3";
        }, say("What is currently on your mind?")),
        ask('feeling'),
        lastFeeling({ input: "feeling" }),
        toLowers({ input: "feeling" }),
        run('image0'),
        run('image3'),
        run('image4'),
        run('image5'),
        run('image6'),
        run('image7'),
        run('image8'),
        run('image9'),
        run('image10'),
        run('image11'),
        run('image12'),
        run('image13'),
        run('bye'),
    ],
    image0: [
        getContext({ key: "image", from: "latest", out: "image" }),
        getRandom({ out: "use_previous_image", p: 0.5 }),
        condition(function (_a) {
            var image = _a.image, use_previous_image = _a.use_previous_image, feeling = _a.feeling;
            return image && use_previous_image && feeling;
        }, say('Last time you mentioned the {{ image }}, is this relevant to feeling {{ feeling }}?')),
        run('image1'),
    ],
    image1: [
        condition(function (_a) {
            var feeling = _a.feeling;
            return feeling;
        }, say('So you are feeling {{ feeling }}. What sort of mental image comes to mind that might describe how you are feeling?'), say('Thanks for sharing that feeling, can you share a mental image that you associate with that feeling?')),
        ask('image'),
        lastNoun({ input: 'image' }),
        run('image2'),
    ],
    image2: [
        randomOption({ out: "image2_question", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var image2_question = _a.image2_question;
            return image2_question === "option1";
        }, say("and what kind of {{ image }} is that?")),
        condition(function (_a) {
            var image2_question = _a.image2_question;
            return image2_question === "option2";
        }, say("and what sort of {{ image }} is that?")),
        condition(function (_a) {
            var image2_question = _a.image2_question;
            return image2_question === "option3";
        }, say("and what type of {{ image }} is that?")),
        ask('image2'),
    ],
    image3: [
        randomOption({ out: "image3_question", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var image3_question = _a.image3_question;
            return image3_question === "option1";
        }, say("And what else is there about that {{ image }}?")),
        condition(function (_a) {
            var image3_question = _a.image3_question;
            return image3_question === "option2";
        }, say("And what other things are there about that {{ image }}?")),
        condition(function (_a) {
            var image3_question = _a.image3_question;
            return image3_question === "option3";
        }, say("And what else do you associate with that {{ image }}?")),
        ask('image3'),
    ],
    image4: [
        randomOption({ out: "image4_question", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var image4_question = _a.image4_question;
            return image4_question === "option1";
        }, say("And where is that {{ image }} located?")),
        condition(function (_a) {
            var image4_question = _a.image4_question;
            return image4_question === "option2";
        }, say("And where about is that {{ image }}?")),
        condition(function (_a) {
            var image4_question = _a.image4_question;
            return image4_question === "option3";
        }, say("And whereabouts is that {{ image }}?")),
        ask('image4'),
    ],
    image5: [
        randomOption({ out: "image5_question", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var image5_question = _a.image5_question;
            return image5_question === "option1";
        }, say("And where might that {{ image }} come from?")),
        condition(function (_a) {
            var image5_question = _a.image5_question;
            return image5_question === "option2";
        }, say("And where might that {{ image }} emerge from?")),
        condition(function (_a) {
            var image5_question = _a.image5_question;
            return image5_question === "option3";
        }, say("And what might be the source of that {{ image }}?")),
        ask('image5'),
    ],
    image6: [
        randomOption({ out: "image6_question", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var image6_question = _a.image6_question;
            return image6_question === "option1";
        }, say("And what happens just before the {{ image }} appears?")),
        condition(function (_a) {
            var image6_question = _a.image6_question;
            return image6_question === "option2";
        }, say("And how do you feel just before the {{ image }} appears?")),
        condition(function (_a) {
            var image6_question = _a.image6_question;
            return image6_question === "option3";
        }, say("And what happens just before the {{ image }} emerges?")),
        ask('image6'),
    ],
    image7: [
        randomOption({ out: "image7_question", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var image7_question = _a.image7_question;
            return image7_question === "option1";
        }, say("And what would you like to have happen when the {{ image }} appears?")),
        condition(function (_a) {
            var image7_question = _a.image7_question;
            return image7_question === "option2";
        }, say("And what would you like to happen for you when the {{ image }} appears?")),
        condition(function (_a) {
            var image7_question = _a.image7_question;
            return image7_question === "option3";
        }, say("And what would you like to have happen when the {{ image }} emerges?")),
        ask('image7'),
    ],
    image8: [
        randomOption({ out: "image8_question", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var image8_question = _a.image8_question;
            return image8_question === "option1";
        }, say("And what is the connection between you and the {{ image }}?")),
        condition(function (_a) {
            var image8_question = _a.image8_question;
            return image8_question === "option2";
        }, say("And what do feel is the connection between you and the {{ image }}?")),
        condition(function (_a) {
            var image8_question = _a.image8_question;
            return image8_question === "option3";
        }, say("And what do you think connects you to the {{ image }}?")),
        ask('image8'),
    ],
    image9: [
        randomOption({ out: "image9_question", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var image9_question = _a.image9_question;
            return image9_question === "option1";
        }, say("And how do you feel about the connection between you and the {{ image }}?")),
        condition(function (_a) {
            var image9_question = _a.image9_question;
            return image9_question === "option2";
        }, say("And how do you feel about that connection between you and the {{ image }}?")),
        condition(function (_a) {
            var image9_question = _a.image9_question;
            return image9_question === "option3";
        }, say("And how do you feel about your connection to the {{ image }}?")),
        ask('image9'),
    ],
    image10: [
        randomOption({ out: "image10_question", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var image10_question = _a.image10_question;
            return image10_question === "option1";
        }, say("And what type of connection would you like to have between you and the {{ image }}?")),
        condition(function (_a) {
            var image10_question = _a.image10_question;
            return image10_question === "option2";
        }, say("And what sort of connection would you like to have between you and the {{ image }}?")),
        condition(function (_a) {
            var image10_question = _a.image10_question;
            return image10_question === "option3";
        }, say("And what type of connection would you like between you and the {{ image }}?")),
        ask('image10'),
    ],
    image11: [
        randomOption({ out: "image11_question", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var image11_question = _a.image11_question;
            return image11_question === "option1";
        }, say("And what would you like to happen to the {{ image }}?")),
        condition(function (_a) {
            var image11_question = _a.image11_question;
            return image11_question === "option2";
        }, say("And what would you like to alter with that {{ image }}?")),
        condition(function (_a) {
            var image11_question = _a.image11_question;
            return image11_question === "option3";
        }, say("And what would you change with that {{ image }}?")),
        ask('image11'),
    ],
    image12: [
        randomOption({ out: "image12_question", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var image12_question = _a.image12_question;
            return image12_question === "option1";
        }, say("And how can you make that happen?")),
        condition(function (_a) {
            var image12_question = _a.image12_question;
            return image12_question === "option2";
        }, say("And what action can you take to make that happen?")),
        condition(function (_a) {
            var image12_question = _a.image12_question;
            return image12_question === "option3";
        }, say("And what steps can you take to make that happen?")),
        ask('image12'),
    ],
    image13: [
        randomOption({ out: "image13_question", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var image13_question = _a.image13_question;
            return image13_question === "option1";
        }, say("And what will be the outcome when you make that happen?")),
        condition(function (_a) {
            var image13_question = _a.image13_question;
            return image13_question === "option2";
        }, say("And what do you feel the outcome will be when you make that happen?")),
        condition(function (_a) {
            var image13_question = _a.image13_question;
            return image13_question === "option3";
        }, say("And what do you sense the outcome will be when you make that happen?")),
        ask('image13'),
    ],
    bye: [
        randomOption({ out: "bye", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var bye = _a.bye;
            return bye === "option1";
        }, say("Thanks for sharing your feelings, bye for now.")),
        condition(function (_a) {
            var bye = _a.bye;
            return bye === "option2";
        }, say("Thanks for sharing your thoughts, bye-bye for now.")),
        condition(function (_a) {
            var bye = _a.bye;
            return bye === "option3";
        }, say("Thanks for exploring your thoughts, goodbye for now.")),
        ask('bye')
    ],
};
